<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for=""> Employé <span class="text-danger">*</span> </label>
        <select
          v-model="search.employee_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
            
          >
            {{ employee.fullName }} {{ employee.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="search.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="search.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="search.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="getReport(search)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Actualiser
        </button>
      </div>
    </div>
    <br />

    <br />
    <div class="bg-white px-2">
      

      <div class="p-0 m-0">
        <div class="row d-flex border border-secondary">
          <div class="col-2 py-2">Référence</div>
          <div class="col-3 py-2">Client</div>
          <div class="col py-2 text-center"> Total TTC </div>

          <div class="col py-2 text-center"> Total Payé</div>
          <div class="col py-2 text-center"> Total Impayé </div>
          <div class="col py-2 text-center"> Total Remise </div>
          <div class="col py-2 text-center"> Quantité Total </div>
        </div>
        <div
          v-for="(report, _index) in reports"
          :key="_index++"
          class="row d-flex border-bottom border-secondary border-start border-end"
        >
          <div class="col-2">
            <span v-if="report.customer">
              {{ report.customer.reference }}
            </span>
          </div>

          <div  class="col-3">
            <span v-if="report.customer">
              {{ report.customer.fullName }}
            </span>
          </div>

          <div class="col text-success text-center">
            {{ parseFloat(report.totalPriceTTC).toFixed(2) | toFixedWithSpace }}
          </div>

          <div class="col text-success text-center">
            {{ parseFloat(report.totalAmountPaid).toFixed(2) | toFixedWithSpace }}
          </div>

          <div class="col text-success text-center">
            {{ parseFloat(report.totalAmountUnpaid).toFixed(2) | toFixedWithSpace }}
          </div>

          <div class="col text-success text-center">
            {{ parseFloat(report.totalDiscountAmount).toFixed(2) | toFixedWithSpace }}
          </div>

          <div class="col text-success text-center">
            {{ parseFloat(report.totalQuantity).toFixed(3) | toFixedWithSpace }}
          </div>
          
        </div>
      </div>
      <br />

      
    </div>

    <div v-if="reports != ''" class="row justify-content-end">
      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="printGeneralReport(search)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Général
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(search)" class="btn btn0 btn-secondary">
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Détaillé
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="archive(search)" class="btn btn-warning">
          <i class="bi bi-archive"></i>
          Archivé
        </button>
      </div>
    </div>

    <!-- <br />
    <div class="row">
      <div class="col">
        <line-chart
          :chart-data="getLineChartDataReport"
          :options="lineChartOptions"
        ></line-chart>
      </div>
    </div> -->
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import LineChart from "../../components/chart/LineChart.vue";

export default {
  components: {
    LineChart,
  },

  data() {
    return {
      search: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      employee_reference: "",
      lineChartData: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "My Line Chart",
            data: [10, 20, 30, 25, 35, 30],
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      lineChartOptions: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 300, // Set the desired height here
      },
    };
  },

  beforeMount() {
    this.$store.dispatch("employee/getAll");
    this.$store.dispatch("sale/reportCustomer", this.search);
    // this.$store.dispatch("sale/show", customer_reference);
  },
  computed: {
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("report", {
      reports: "getEmployeeReport",
     
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("employee/getAll");
      this.search = {};
    },
    async getReport(data) {
      await this.$store.dispatch("report/fetchReportEmployee", data);
    },
    async print(data) {
      await this.$store.dispatch("report/printReportCustomer", data);
    },
    async printGeneralReport(data) {
      await this.$store.dispatch("report/printGeneralReportEmployee", data);
    },
    async archive(data) {
      await this.$confirm({
        message:
          "Souhaitez-vous mettre en archives l'ensemble des factures et paiements liés à ce client ?",
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("archive/storeCustomerReport", data);
            this.$store.dispatch("report/reportCustomer", data);
          }
        },
      });
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
